import React from "react"
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";
import SoftwareDownloadButton from "../../../../components/SoftwareDownload/SoftwareDownloadButton";
import {LocaleContext} from "../../../../components/layout";

const Psychrometer = () => {
    const { locale } = React.useContext(LocaleContext)
    const PsychrometerJa = () => {
        return(
            <div className="content-container">
                <h2 className={'large bold'}>通風乾湿計による湿度計算シート ダウンロード</h2>

                <div className="software-content">
                    <div className="terms-of-service-container">
                        <div className="content-pre">
                            <p className="equal-line">＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</p>
                            <p>このドキュメントは本製品に関する重要な情報について書かれています。<br/>
                                ご使用の前に、必ずご一読くださるようお願いいたします。</p>
                            <p className="equal-line">＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</p>
                        </div>
                        <div className="content-pre">
                            <p>
                                通風乾湿計計算ツールはエクセルシートで作成されています。
                                <br/>
                                インターネットでの配信形式の性格上、マクロ等の自動実行プラグイン等は使用しておりません。
                                <br/>
                                従いまして、ありえない値の入力によるエラー等、エラー処理に対する回避処理はおこなっておりませんのでご了承ください。
                            </p>
                            <p> また、以下の利用規約に同意いただけた方のみ、ソフトウェアをご利用いただけます。</p>
                        </div>
                        <div className="content-title">
                            <p>＜利用規約＞</p>
                        </div>
                        <div className="content-disclaimer">
                            <div className="content-disclaimer-title">
                                <p> ■免責事項</p>
                            </div>
                            <div className="content-disclaimer-content quote">
                                <p>
                                    株式会社佐藤計量器製作所は本ソフトウェア製品に関して動作確認はおこなっておりますが、全ての状況下において動作を保証しているわけではありません。<br/>
                                    本製品により生じた損害は、直接的および間接的損害を問わず、弊社に一切の賠償責任はないものとします。<br/>
                                    また本ソフトウェアに関するお問い合わせはご容赦ねがいます。
                                </p>
                            </div>
                        </div>
                        <div className="content-copyright">
                            <div className="content-copyright-title">
                                <p> ■著作権</p>
                            </div>
                            <div className="content-copyright-content">
                                <ol>
                                    <li>
                                        「通風乾湿計による湿度計算シート」に関する著作権は株式会社佐藤計量器製作所に帰属します。                                            </li><br/>
                                    <li>
                                        「通風乾湿計による湿度計算シート」は以下条件をもとに、無償でご利用いただけます。                                                <ul>
                                        <li>
                                            <p>ファイル解析、改変等を禁じます。</p>
                                        </li>
                                        <li>
                                            <p>本ソフトウェアの使用による損害は、弊社は一切の責任を負いません。</p>
                                        </li>
                                        <li>
                                            <p>再配布は自由としますが、企業内・企業間、その他関係においても営利目的にしないこと。
                                                <br/>
                                                再配布したソフトウェアの使用による損害に対しても弊社は一切の責任を負いません。
                                            </p>
                                        </li>
                                    </ul>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="download">
                        <div className="download-title">
                            <p className="bold">【ソフトウェアダウンロード】</p>
                        </div>
                        <div className="download-content">
                            <table>
                                <tbody>
                                <tr>
                                    <th>
                                        <p className="download-table-title">通風乾湿計による湿度 <br/>計算シート</p>
                                    </th>
                                    <td>
                                        <SoftwareDownloadButton
                                            yourKey="corporate/pdfs/Psychrometer Ver.1.2J.xls"
                                            tmpKey="pdfs/Psychrometer Ver.1.2J.xls"
                                        />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const PsychrometerEn = () => {
        return(
            <div className="content-container">
                <h2 className={'large bold'}>Aspirated Psychrometer Calculation Sheet Download</h2>
                <div className="software-content">
                    <div className="terms-of-service-container">
                        <div className="content-pre">
                            <p className="equal-line">＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</p>
                            <p>The "Aspirated Psychrometer Calculation Sheet" runs on MS Excel.</p>
                            <p className="equal-line">＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</p>
                        </div>
                        <div className="contents">
                            <p>
                                Since it is designed to be downloaded from Internet, it contains no auto executing macro or plug-in. Accordingly, no error handling routine is provided to recover from errors, for example an error that occurs if an abnormal value is entered.
                            </p>
                            <p>
                                Please note that the distributor and copyright holder of this program will not bear any responsibility for damages and/or losses resulting from the use of this program.
                            </p>
                            <p>
                                Aspirated Psychrometer Calculation Sheet is copyrighted by Sato Keiryoki, but you may freely copy it except for commercial uses. Also, note that we will not respond to any inquiries about this program.
                            </p>
                        </div>
                    </div>
                    <div className="download">
                        <div className="download-title">
                            <p className="bold">Software Download Site</p>
                        </div>
                        <div className="download-content">
                            <table>
                                <tbody>
                                <tr>
                                    <th>
                                        <p className="download-table-title">Program Name</p>
                                    </th>
                                    <td>
                                        <p>Aspirated Psychrometer Calculation Sheet Ver 1.1E</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">Download</p>
                                    </th>
                                    <td>
                                        <SoftwareDownloadButton
                                            yourKey="corporate/pdfs/Psychrometer1.1E.xls"
                                            tmpKey="pdfs/Psychrometer1.1E.xls"
                                        />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <article className="company-info two-column-left-nav">
                <SupportLeftNav/>
                <section className="main-content">
                    {(locale === 'ja')?
                        <PsychrometerJa/>:
                        <PsychrometerEn/>
                    }
                </section>
            </article>
            <Footer/>
        </>
    )
}

export default Psychrometer
